<!-- 区企互动 -->
<template>
  <div class="HyBg">
    <div style="padding-bottom: 30px">
      <div class="swipebox">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img
              src="https://lycaljdapi.dexian.ren//upload/images/2024/1/2fc7500f-d77.jpg"
              alt=""
              style="width: 100%; border-radius: 8px"
            />
          </van-swipe-item>
          <van-swipe-item>
            <img
              src="https://lycaljdapi.dexian.ren//upload/images/2024/1/642f1da9-136.jpg"
              alt=""
              style="width: 100%; border-radius: 8px"
            />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="pushUrl clearfix">
        <!-- <router-link to="/home/interaction/introduces">
        <div style="float: left;" class="bga">
          <span>园 区 介 绍</span>
        </div>
      </router-link>
      <router-link to="/home/interaction/department">
        <div style="float: right;" class="bgb">
          <span>部 门 职 能</span>
        </div>
      </router-link> -->
      </div>
      <div class="inputFrom">
        <div style="padding: 15px 0px; position: relative">
          <span class="icontop"></span><span class="icontitle">政企互动</span>
          <!-- <router-link to="/home/interaction/lianluo"><span class="lianxi">联系我们</span></router-link> -->
        </div>
        <div style="margin-top: 10px">
          <van-field v-model="datafrom.Name" label="名称" placeholder="请输入提交人名称" />
          <div class="rateBox">
            <span class="title">平台评分</span>
            <van-rate
              v-model="datafrom.Live"
              :size="25"
              :gutter="10"
              color="#FF9E14"
              class="rate"
              @change="clickRate"
            />
          </div>
          <van-field
            v-model="datafrom.Idea"
            rows="4"
            autosize
            class="fieldadd"
            label="意见建议"
            type="textarea"
            maxlength="300"
            placeholder="请输入您的意见建议"
            show-word-limit
          />
          <div class="fromButton">
            <van-button style="width: 80%; margin: 10px 10%; border-radius: 25px" type="info" @click="tijiao()"
              >提 交</van-button
            >
          </div>
        </div>
      </div>
      <div class="inputFrom">
        <div style="padding: 15px 0px"><span class="icontop"></span><span class="icontitle">意见回复</span></div>
        <div>
          <van-collapse v-model="activeNames" accordion>
            <van-collapse-item
              v-for="item in InteractList"
              :key="item.IId"
              :title="item.title"
              :name="item.IId"
              icon="comment-circle"
            >
              <div class="conter">
                <div><span>发 起 人: </span> {{ item.Name }}</div>
                <div><span>意见内容: </span> {{ item.Idea }}</div>
                <div>
                  <span>发起时间:</span>
                  <span style="color: #ccc"> {{ item.SubTime }}</span>
                </div>
                <div style="margin-top: 15px">
                  <span style="color: #19d36c">园区回复: </span>
                  {{ item.ReplyContent }}
                </div>
                <div>
                  <span style="color: #19d36c">回复时间: </span>
                  <span style="color: #ccc"> {{ item.ReplyTime }}</span>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
          <van-empty description="暂无互动内容 欢迎您投出宝贵意见" v-if="!InteractList.length" />
        </div>
      </div>
    </div>
    <!-- <div>
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o" to="/huayang/classroom"
          >知识讲堂</van-tabbar-item
        >
        <van-tabbar-item icon="qr" to="/huayang/serve"
          >资源看板</van-tabbar-item
        >
        <van-tabbar-item icon="apps-o" to="/huayang">中介超市</van-tabbar-item>
        <van-tabbar-item icon="cluster-o" to="/huayang/interaction"
          >区企互动</van-tabbar-item
        >
        <van-tabbar-item icon="phone-circle-o" to="/huayang/lianxi"
          >联系我们</van-tabbar-item
        >
      </van-tabbar>
    </div> -->
  </div>
</template>
<script>
import Vue from 'vue'
import { Rate } from 'vant'
import { Dialog } from 'vant'
import { WeSubInteract, GetInteractPage } from '@/api/Hyapi'

Vue.use(Dialog)
Vue.use(Rate)
export default {
  data() {
    return {
      active: 3,
      activeNames: '',
      datafrom: {
        EId: 0, //企业Id、
        Idea: '', //意见建议、
        Live: '',
        Name: '',
        Remark: '', //备注
      },
      Interact: {
        page: 1,
        limit: 15,
        status: 2,
      },
      InteractList: {},
    }
  },
  mounted() {
    this.InteractPage()
  },
  methods: {
    // 评分
    clickRate() {},
    // 获取回复列表
    InteractPage() {
      GetInteractPage(this.Interact).then((res) => {
        console.log(res)
        this.InteractList = res.data.data
        for (let i = 0; i < this.InteractList.length; i++) {
          if (this.InteractList[i].Idea.length > 18) {
            this.InteractList[i].title = this.InteractList[i].Idea.slice(0, 17) + '...'
          } else {
            this.InteractList[i].title = this.InteractList[i].Idea
          }
          if (this.InteractList[i].Name == '') {
            this.InteractList[i].Name = '匿名'
          }
        }
      })
    },
    // 提交
    tijiao() {
      if (this.datafrom.Live == '') {
        Dialog.alert({
          title: '提示',
          message: '请评分!',
        }).then(() => {
          // on close
        })
        return false
      }
      if (this.datafrom.Idea == '') {
        Dialog.alert({
          title: '提示',
          message: '请输入意见建议!',
        }).then(() => {
          // on close
        })
        return false
      }
      WeSubInteract(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: '提示',
            message: '提交成功!',
          }).then(() => {
            this.datafrom.EId = undefined
            this.datafrom.Live = ''
            this.datafrom.Idea = ''
          })
        } else {
          Dialog.alert({
            title: '提示',
            message: '提交失败!',
          }).then(() => {})
        }
      })
    },
  },
}
</script>
<style>
.HyBg {
  background: #fafafa;
}
.swipebox {
  width: 90%;
  margin: 15px 5%;
  margin-bottom: 10px;
}
.inputFrom {
  width: 90%;
  margin: 15px 5%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgb(241, 238, 238);
}
.fieldadd .van-field__control {
  background: #fafafa !important;
  border-radius: 10px;
  font-size: 14px;
  padding: 5px 8px;
}
.rateBox {
  margin: 10px 15px;
  position: relative;
  border-bottom: 1px solid #ebedf0;
  padding-bottom: 12px;
}
.title {
  color: #646566;
  font-size: 14px;
  line-height: 24px;
}
.rate {
  position: absolute;
  left: 95px;
}
.lianxi {
  position: absolute;
  right: 25px;
  top: 20px;
  color: #1989fa;
  font-size: 14px;
}
.icontop {
  margin-left: 15px;
  display: inline-block;
  width: 4px;
  height: 15px;
  background: #015293;
}
.icontitle {
  padding-left: 10px;
  font-size: 18px;
  font-weight: 700;
}
.margin {
  margin: 15px 30px;
}
.onRecordn {
  position: absolute;
  right: 15px;
  bottom: 27px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background: #1989fa;
  color: #fff;
  font-size: 14px;
}
.conter {
  color: #333;
}
.conter span {
  color: #1989fa;
}
.pushUrl {
  width: 90%;
  margin: 0 5%;
}
.pushUrl div {
  display: inline-block;
  width: 47.5%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-size: 100%;
  color: #fff;
  border-radius: 8px;
}
.bga {
  background: url('../../assets/img_company_bj3@2x.png');
}
.bgb {
  background: url('../../assets/img_company_bj1@2x.png');
}
</style>
